<template>
  <div>
    <form @submit.prevent="save">
      <div class="col-lg-12">
        <b-tabs
          justified
          nav-class="nav-tabs-custom"
          content-class="p-3 text-muted"
        >
          <b-tab active>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">Dados</span>
            </template>
            <!-- NOME | TIPO DE CLIENTE | DATA DE INICIO -->
            <div class="form-row">
              <div class="form-group col-sm-3">
                <label>Tipo: <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  :class="{ 'is-invalid': $v.client.tipo_cliente.$error }"
                  v-model="$v.client.tipo_cliente.$model"
                >
                  <option value="">Selecione o tipo de cliente</option>
                  <option value="2">Pessoa Física</option>
                  <option value="1">Pessoa Jurídica</option>
                </select>
                <span
                  v-if="!$v.client.tipo_cliente.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div v-if="client.tipo_cliente == 1" class="form-group col-sm-6">
                <label>Nome fantasia: <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.client.name.$error }"
                  placeholder="Nome fantasia"
                  v-model="$v.client.name.$model"
                />
                <span v-if="!$v.client.name.required" class="invalid-feedback">
                  O campo é obrigatório
                </span>
                <span v-if="!$v.client.name.minLength" class="invalid-feedback">
                  O nome deve possuir pelo menos 8 caracteres
                </span>
              </div>
              <div v-if="client.tipo_cliente == 2" class="form-group col-sm-6">
                <label>Nome: <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.client.name.$error }"
                  placeholder="Nome completo"
                  v-model="$v.client.name.$model"
                />
                <span v-if="!$v.client.name.required" class="invalid-feedback">
                  O campo é obrigatório
                </span>
                <span v-if="!$v.client.name.minLength" class="invalid-feedback">
                  O nome deve possuir pelo menos 8 caracteres
                </span>
              </div>
              <div class="form-group col-sm-3">
                <label>Cliente desde: <span class="text-danger">*</span></label>
                <input
                  type="date"
                  class="form-control"
                  :class="{
                    'is-invalid': $v.client.cliente_data_inicio.$error,
                  }"
                  placeholder="Data de nascimento"
                  v-model="$v.client.cliente_data_inicio.$model"
                />
                <span
                  v-if="!$v.client.cliente_data_inicio.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>

            <!-- CPF/CNPJ | RAZÃO SOCIAL |  E RG -->
            <div class="form-row">
              <div v-if="client.tipo_cliente == 1" class="form-group col-sm-4">
                <label>CNPJ: <span class="text-danger">*</span></label>
                <the-mask
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.client.cpfcnpj.$error }"
                  placeholder="00.000.000/000-00"
                  v-model="$v.client.cpfcnpj.$model"
                  :mask="'##.###.###/####-##'"
                  :masked="true"
                />
                <span
                  v-if="!$v.client.cpfcnpj.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
                <span
                  v-if="!$v.client.cpfcnpj.minLength"
                  class="invalid-feedback"
                >
                  Formato inválido <br />
                  Ex: 00.000.000/0000-00 / 000.000.000-00
                </span>
              </div>
              <div v-if="client.tipo_cliente == 2" class="form-group col-sm-4">
                <label>CPF: <span class="text-danger">*</span></label>
                <the-mask
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.client.cpfcnpj.$error }"
                  placeholder="000.000.000-00"
                  v-model="$v.client.cpfcnpj.$model"
                  :mask="'###.###.###-##'"
                  :masked="true"
                />
                <span
                  v-if="!$v.client.cpfcnpj.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
                <span
                  v-if="!$v.client.cpfcnpj.minLength"
                  class="invalid-feedback"
                >
                  Formato inválido <br />
                  Ex: 00.000.000/0000-00 / 000.000.000-00
                </span>
              </div>
              <div v-if="client.tipo_cliente == 1" class="form-group col-sm-8">
                <label>Razão social:</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Razão Social"
                  v-model="client.razao_social"
                />
              </div>
              <div v-if="client.tipo_cliente == 2" class="form-group col-sm-4">
                <label>RG: <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nº do RG"
                  v-model="client.rg"
                />
              </div>
              <div v-if="client.tipo_cliente == 2" class="form-group col-sm-4">
                <label
                  >Data de nascimento: <span class="text-danger">*</span></label
                >
                <input
                  type="date"
                  class="form-control"
                  placeholder="Data de nascimento"
                  v-model="client.data_nascimento"
                />
              </div>
            </div>

            <div v-if="client.tipo_cliente == 1" class="form-row">
              <div class="form-group col-sm-3">
                <label>Natureza Jurídica: </label>
                <select class="form-control" v-model="client.natureza_juridica">
                  <option value="">Selecione uma opção</option>
                  <option value="1">MEI</option>
                  <option value="2">ME</option>
                  <option value="2">LTDA</option>
                  <option value="2">EIRELI</option>
                  <option value="2">SA</option>
                  <option value="2">UNILATERAL</option>
                  <option value="2">N.D.</option>
                </select>
              </div>
              <div class="form-group col-sm-3">
                <label>Natureza tributária: </label>
                <select
                  class="form-control"
                  v-model="client.natureza_tributaria"
                >
                  <option value="">Selecione uma opção</option>
                  <option value="1">Simples Nacional</option>
                  <option value="2">Lucro Presumido</option>
                  <option value="2">Lucro Real</option>
                </select>
              </div>
              <div class="form-group col-sm-3">
                <label>Inscrição Municipal: </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Digite o número da I.M."
                  v-model="client.inscricao_municipal"
                />
              </div>
              <div class="form-group col-sm-3">
                <label>Inscrição Estadual: </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Digite o número da I.E."
                  v-model="client.inscricao_estadual"
                />
              </div>
            </div>

            <!-- EMAIL E CELULAR -->
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>E-mail: <span class="text-danger">*</span></label>
                <input
                  type="email"
                  class="form-control"
                  :class="{ 'is-invalid': $v.client.email.$error }"
                  v-model="$v.client.email.$model"
                  placeholder="usuario@email.com.br"
                />
                <span v-if="!$v.client.email.email" class="invalid-feedback">
                  Formato de e-mail inválido
                </span>
              </div>
              <div class="form-group col-sm-6">
                <label>Telefone:</label>
                <the-mask
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.client.telefone.$error }"
                  placeholder="(00) 98888-8888"
                  v-model="$v.client.telefone.$model"
                  :mask="['(##) ####-####', '(##) #####-####']"
                  :masked="true"
                />
                <span
                  v-if="!$v.client.telefone.minLength"
                  class="invalid-feedback"
                >
                  Formato de telefone inválido <br />
                  Ex: (00) 98888-8888 / (00) 8888-8888
                </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>Observação:</label>
                <textarea
                  type="text"
                  class="form-control"
                  v-model="client.observacao"
                />
              </div>
              <div v-if="clientId" class="form-group col-sm-6">
                <p>Status: <span class="text-danger">*</span></p>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="statusInativo"
                    value="0"
                    v-model="client.status"
                  />
                  <label class="form-check-label" for="statusInativo">
                    Inativo
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="statusAtivo"
                    value="1"
                    v-model="client.status"
                  />
                  <label class="form-check-label" for="statusAtivo">
                    Ativo
                  </label>
                </div>
              </div>
            </div>
          </b-tab>

          <!-- ENDEREÇO -->
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block">Endereço</span>
            </template>
            <div class="form-row">
              <div class="form-group col-sm-8">
                <label>Endereço: <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.client.endereco.$error }"
                  placeholder="Rua, Av., Tv..."
                  v-model="$v.client.endereco.$model"
                />
                <span
                  v-if="!$v.client.endereco.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-group col-sm-4">
                <label>Nº: <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.client.num_endereco.$error }"
                  placeholder="Nº"
                  v-model="$v.client.num_endereco.$model"
                />
                <span
                  v-if="!$v.client.num_endereco.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>

            <!-- BAIRRO, ESTADO E CIDADE -->
            <div class="form-row">
              <div class="form-group col-sm-4">
                <label>Bairro:</label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.client.bairro.$error }"
                  placeholder="Nome do bairro"
                  v-model="$v.client.bairro.$model"
                />
                <span
                  v-if="!$v.client.bairro.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-group col-sm-4">
                <label>Estado: <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  :class="{ 'is-invalid': $v.client.state_id.$error }"
                  v-model="$v.client.state_id.$model"
                >
                  <option value="">Selecione o estado</option>
                  <option
                    v-for="state in states"
                    :key="state.id"
                    :value="state.id"
                    :title="`${state.uf} - ${state.name}`"
                  >
                    {{ state.uf }}
                  </option>
                </select>
              </div>
              <div class="form-group col-sm-4">
                <label>Cidade: <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  :class="{ 'is-invalid': $v.client.city_id.$error }"
                  v-model="$v.client.city_id.$model"
                >
                  <option value="">Selecione uma cidade</option>
                  <option
                    v-for="city in cities"
                    :key="city.id"
                    :value="city.id"
                    :title="city.name"
                  >
                    {{ city.name }}
                  </option>
                </select>
                <span
                  v-if="!$v.client.city_id.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>

            <!-- ENDEREÇO -->
            <div class="form-row">
              <div class="form-group col-sm-4">
                <label>País:<span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.client.pais.$error }"
                  placeholder="Digite o país"
                  v-model="$v.client.pais.$model"
                />
                <span v-if="!$v.client.pais.required" class="invalid-feedback">
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-group col-sm-6">
                <label>Complemento:</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Complemento do endereço"
                  v-model="client.complemento"
                />
              </div>
              <div class="form-group col-sm-2">
                <label>CEP:<span class="text-danger">*</span></label>
                <the-mask
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.client.cep.$error }"
                  placeholder="00000-000"
                  v-model="$v.client.cep.$model"
                  :mask="'#####-###'"
                  :masked="true"
                />
              </div>
              <span v-if="!$v.client.cep.required" class="invalid-feedback">
                O campo é obrigatório
              </span>
            </div>
          </b-tab>
          <!-- DADOS DO RESPONSÁVEL -->
          <b-tab v-if="client.tipo_cliente == 1">
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block"
                >Dados do Responsável legal</span
              >
            </template>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>Nome: <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.client.nome_responsavel.$error }"
                  placeholder="Nome completo do responsável"
                  v-model="$v.client.nome_responsavel.$model"
                />
                <span
                  v-if="!$v.client.nome_responsavel.minLength"
                  class="invalid-feedback"
                >
                  O nome deve possuir pelo menos 8 caracteres
                </span>
              </div>
              <div class="form-group col-sm-3">
                <label>Sexo: <span class="text-danger">*</span></label>
                <select class="form-control" v-model="client.sexo_responsavel">
                  <option value="">Selecione o sexo</option>
                  <option value="1">Feminino</option>
                  <option value="2">Masculino</option>
                </select>
              </div>
              <div class="form-group col-sm-3">
                <label
                  >Data de nascimento: <span class="text-danger">*</span></label
                >
                <input
                  type="date"
                  class="form-control"
                  placeholder="Data de nascimento"
                  v-model="client.data_nascimento_responsavel"
                />
              </div>
            </div>

            <!-- CPF E RG DO RESPONSÁVEL -->
            <div class="form-row">
              <div class="form-group col-sm-4">
                <label>CPF: <span class="text-danger">*</span></label>
                <the-mask
                  type="text"
                  class="form-control"
                  placeholder="000.000.000-00"
                  v-model="client.cpf_responsavel"
                  :mask="'###.###.###-##'"
                  :masked="true"
                />
              </div>
              <div class="form-group col-sm-4">
                <label>RG:</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nº do RG"
                  v-model="client.rg_responsavel"
                />
              </div>
              <div class="form-group col-sm-4">
                <label>Órgão expeditor:</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Órgão expeditor do RG"
                  v-model="client.orgao_expeditor_rg_responsavel"
                />
              </div>
            </div>

            <!-- NATURALIDADE, NACIONALIDADE E ESTADO CIVIL -->
            <div class="form-row">
              <div class="form-group col-sm-4">
                <label>Estado civil: <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  v-model="client.estado_civil_responsavel"
                >
                  <option value="">Selecione o estado civil</option>
                  <option value="1">Solteiro</option>
                  <option value="2">Casado</option>
                  <option value="2">Separado</option>
                  <option value="2">Divorciado</option>
                  <option value="2">Viúvo</option>
                </select>
              </div>
              <div class="form-group col-sm-4">
                <label>Naturalidade: <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Naturalidade do responsável"
                  v-model="client.naturalidade_responsavel"
                />
              </div>
              <div class="form-group col-sm-4">
                <label>Nacionalidade: <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="nacionalidade do responsável"
                  v-model="client.nacionalidade_responsavel"
                />
              </div>
            </div>

            <!-- EMAIL E CELULAR -->
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>E-mail: <span class="text-danger">*</span></label>
                <input
                  type="email"
                  class="form-control"
                  :class="{
                    'is-invalid': $v.client.email_responsavel.$error,
                  }"
                  v-model="$v.client.email_responsavel.$model"
                  placeholder="usuario@email.com.br"
                />
                <span
                  v-if="!$v.client.email_responsavel.email"
                  class="invalid-feedback"
                >
                  Formato de e-mail inválido
                </span>
              </div>
              <div class="form-group col-sm-6">
                <label>Telefone:</label>
                <the-mask
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': $v.client.telefone_responsavel.$error,
                  }"
                  placeholder="(00) 98888-8888"
                  v-model="$v.client.telefone_responsavel.$model"
                  :mask="['(##) ####-####', '(##) #####-####']"
                  :masked="true"
                />
                <span
                  v-if="!$v.client.telefone_responsavel.minLength"
                  class="invalid-feedback"
                >
                  Formato de telefone inválido <br />
                  Ex: (00) 98888-8888 / (00) 8888-8888
                </span>
              </div>
            </div>

            <!-- ENDEREÇO -->
            <div class="form-row">
              <div class="form-group col-sm-8">
                <label>Endereço: <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Rua, Av., Tv..."
                  v-model="client.endereco_responsavel"
                />
              </div>
              <div class="form-group col-sm-4">
                <label>Nº: <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nº"
                  v-model="client.num_endereco_responsavel"
                />
              </div>
            </div>

            <!-- BAIRRO, ESTADO E CIDADE -->
            <div class="form-row">
              <div class="form-group col-sm-4">
                <label>Bairro:</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nome do bairro"
                  v-model="client.bairro_responsavel"
                />
              </div>
              <div class="form-group col-sm-4">
                <label>Estado: <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  v-model="client.state_id_responsavel"
                >
                  <option value="">Selecione o estado</option>
                  <option
                    v-for="state in states"
                    :key="state.id"
                    :value="state.id"
                    :title="`${state.uf} - ${state.name}`"
                  >
                    {{ state.uf }}
                  </option>
                </select>
              </div>
              <div class="form-group col-sm-4">
                <label>Cidade: <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  v-model="client.city_id_responsavel"
                >
                  <option value="">Selecione uma cidade</option>
                  <option
                    v-for="city in cities_responsavel"
                    :key="city.id"
                    :value="city.id"
                    :title="city.name"
                  >
                    {{ city.name }}
                  </option>
                </select>
              </div>
            </div>

            <!-- ENDEREÇO -->
            <div class="form-row">
              <div class="form-group col-sm-4">
                <label>País:<span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Digite o país"
                  v-model="client.pais_responsavel"
                />
              </div>
              <div class="form-group col-sm-6">
                <label>Complemento:</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Complemento do endereço"
                  v-model="client.complemento_responsavel"
                />
              </div>
              <div class="form-group col-sm-2">
                <label>CEP:<span class="text-danger">*</span></label>
                <the-mask
                  type="text"
                  class="form-control"
                  placeholder="00000-000"
                  v-model="client.cep_responsavel"
                  :mask="'#####-###'"
                  :masked="true"
                />
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block">Contatos</span>
            </template>
            <div>
              <button
                type="button"
                @click="addContato()"
                class="btn btn-primary"
                title="Adicionar rasgadinha"
              >
                <i class="bx ri-add-line"></i> Novo contato
              </button>
            </div>
            <div role="tablist">
              <div
                v-for="(etapa, index) in contatos"
                :key="index"
                class="card task-box"
              >
                <b-card no-body class="mb-1 shadow-none">
                  <b-card-header header-tag="header" role="tab">
                    <div class="form-row">
                      <div class="form-group col-sm-1">
                        <br />
                        <a
                          v-b-toggle="'accordion-' + index"
                          class="btn btn-lg btn-rounded btn-outline-info"
                          href="javascript: void(0);"
                          >{{ index + 1 }}</a
                        >
                      </div>
                      <div class="form-group col-sm-6">
                        <label>Nome: <span class="text-danger">*</span></label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Nome completo"
                          :name="'contatos[' + index + '].[nome]'"
                          :id="'contatos[' + index + '].[nome]'"
                          v-model="contatos[index].nome"
                        />
                      </div>
                      <div class="form-group col-sm-4">
                        <label>Aniversário:</label>
                        <input
                          type="date"
                          class="form-control"
                          placeholder="Data de Nascimento"
                          :name="'contatos[' + index + '].[aniversario]'"
                          :id="'contatos[' + index + '].[aniversario]'"
                          v-model="contatos[index].aniversario"
                        />
                      </div>
                      <div class="form-group col-sm-1">
                        <br />
                        <button
                          type="button"
                          @click="deleteContato(index)"
                          class="btn btn-lg btn-outline-danger"
                          title="Adicionar item"
                        >
                          <i class="bx ri-delete-bin-line"></i>
                        </button>
                      </div>
                    </div>
                  </b-card-header>
                  <b-collapse
                    :id="'accordion-' + index"
                    visible
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <!-- EMAIL E CELULAR -->
                      <div class="form-row">
                        <div class="form-group col-sm-4">
                          <label
                            >E-mail: <span class="text-danger">*</span></label
                          >
                          <input
                            type="email"
                            class="form-control"
                            :name="'contatos[' + index + '].[email]'"
                            :id="'contatos[' + index + '].[email]'"
                            v-model="contatos[index].email"
                            placeholder="usuario@email.com.br"
                          />
                        </div>
                        <div class="form-group col-sm-4">
                          <label>Telefone:</label>
                          <the-mask
                            type="text"
                            class="form-control"
                            placeholder="(00) 0000-0000"
                            :name="'contatos[' + index + '].[telefone]'"
                            :id="'contatos[' + index + '].[telefone]'"
                            v-model="contatos[index].telefone"
                            :mask="['(##) ####-####']"
                            :masked="true"
                          />
                        </div>
                        <div class="form-group col-sm-4">
                          <label>Celular:</label>
                          <the-mask
                            type="text"
                            class="form-control"
                            placeholder="(00) 98888-8888"
                            :name="'contatos[' + index + '].[celular]'"
                            :id="'contatos[' + index + '].[celular]'"
                            v-model="contatos[index].celular"
                            :mask="['(##) ####-####', '(##) #####-####']"
                            :masked="true"
                          />
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <router-link :to="`clients`" class="btn btn-secondary" title="Detalhes">
          Cancelar
        </router-link>
        <button
          type="submit"
          class="btn btn-primary"
          :title="textModal"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>{{ textModal }}</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'

export default {
  name: 'CreateClientComponent',

  components: {
    TheMask,
  },

  props: {
    clientId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      client: {
        city_id: '',
        state_id: '',
        name: '',
        cpfcnpj: '',
        tipo_cliente: 2,
        razao_social: '',
        natureza_tributaria: '',
        cliente_data_inicio: '',
        inscricao_estadual: '',
        inscricao_municipal: '',
        natureza_juridica: '',
        telefone: '',
        email: '',
        rg: '',
        data_nascimento: '',
        endereco: '',
        num_endereco: '',
        bairro: '',
        cep: '',
        pais: '',
        complemento: '',
        nome_responsavel: '',
        email_responsavel: '',
        telefone_responsavel: '',
        nacionalidade_responsavel: '',
        naturalidade_responsavel: '',
        cpf_responsavel: '',
        rg_responsavel: '',
        orgao_expeditor_rg_responsavel: '',
        estado_civil_responsavel: '',
        data_nascimento_responsavel: '',
        sexo_responsavel: '',
        endereco_responsavel: '',
        num_endereco_responsavel: '',
        bairro_responsavel: '',
        state_id_responsavel: '',
        city_id_responsavel: '',
        pais_responsavel: '',
        cep_responsavel: '',
        complemento_responsavel: '',
        observacao: '',
      },
      contatos: [],
      disableButton: false,
      states: null,
      cities: null,
      cities_responsavel: null,
      loadDataEditing: false,
    }
  },

  computed: {
    textModal() {
      return this.clientId ? 'Atualizar' : 'Cadastrar'
    },
  },

  watch: {
    'client.state_id': {
      handler(newValue, oldValue) {
        if (!this.loadDataEditing) {
          this.client.city_id = ''
        }
        if (newValue !== '') {
          this.getCities()
        }
      },
    },

    'client.state_id_responsavel': {
      handler(newValue, oldValue) {
        if (!this.loadDataEditing) {
          this.client.city_id_responsavel = ''
        }
        if (newValue !== '') {
          this.getCitiesResponsavel()
        }
      },
    },
  },

  methods: {
    addContato() {
      this.contatos.push({
        nome: '',
        aniversario: '',
        email: '',
        telefone: '',
        celular: '',
      })
    },
    deleteContato(index) {
      this.contatos.splice(index, 1)
    },

    async getStates() {
      try {
        this.states = await this.$store.dispatch('getStates')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getCities() {
      try {
        this.cities = await this.$store.dispatch(
          'getCities',
          this.client.state_id,
        )
        this.loadDataEditing = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getCitiesResponsavel() {
      try {
        this.cities_responsavel = await this.$store.dispatch(
          'getCities',
          this.client.state_id_responsavel,
        )
        this.loadDataEditing = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getClient() {
      try {
        this.loadDataEditing = true
        this.client = await this.$store.dispatch('getClient', this.clientId)
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.clientId) {
            response = await this.$store.dispatch('updateClient', this.client)
          } else {
            response = await this.$store.dispatch('saveClient', {
              client: this.client,
              contatos: this.contatos,
            })
          }
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
          })
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    client: {
      name: {
        required,
        minLength: minLength(8),
      },
      cpfcnpj: {
        required,
        minLength: minLength(14),
        maxLength: maxLength(18),
      },
      telefone: {
        minLength: minLength(14),
        maxLength: maxLength(15),
      },
      email: { email },
      state_id: { required },
      city_id: { required },
      tipo_cliente: { required },
      cliente_data_inicio: { required },
      endereco: { required },
      num_endereco: { required },
      bairro: { required },
      cep: { required },
      pais: { required },
      nome_responsavel: { minLength: minLength(8) },
      email_responsavel: { email },
      telefone_responsavel: {
        minLength: minLength(14),
        maxLength: maxLength(15),
      },
    },
  },

  mounted() {
    this.getStates()
    if (this.clientId) {
      this.getClient()
    }
  },
}
</script>
